<template>
  <b-container class="text-center">
    <base-logo-wave class="logo-maintenance-mode" :white=true />

    <h1>{{ $t('maintenance_mode.title') }}</h1>
    <p>{{ $t('maintenance_mode.description') }}</p>

  </b-container>
</template>

<script>
import BaseLogoWave from '../components/BaseLogoWave.vue'

export default {
  components: {
    BaseLogoWave
  },
  data () {
    return {
      env: {
        landing_page_url: process.env.VUE_APP_LANDING_PAGE_URL
      }
    }
  }
}
</script>

<style lang="scss">
body.maintenance-mode {
  background-image: linear-gradient(
    129deg,
    var(--blueberry-two),
    var(--flat-blue) 51%,
    var(--aqua-marine)
  );
}
</style>

<style scoped lang="scss">
.logo-maintenance-mode {
  margin-bottom: 70px;
}

h1 {
  font-size: 26px;
  color: var(--white);
  margin-bottom: 14px;
  font-weight: 400;
}

p {
  font-family: 'Athelas', arial, sans-serif;
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.1px;
  color: var(--white);
}
</style>
